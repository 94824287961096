<template>
  <div
    id="admin-login-page"
    class="justify-content-center">
    <b-row
      align-v="center"
      align-h="center"
      no-gutters>
      <b-col cols="10" xl="3">
        <div class="text-center">
          <img
            class="h3-logo"
            src="../../../assets/images/Healthy-Hip-Hop-Logo.png"
            alt="h3-logo"
          />
        </div>
        <div class="login-form">
          <b-form @submit.prevent="adminLogin">
            <b-form-group>
              <b-form-input
                id="username"
                aria-describedby="username-input"
                type="text"
                placeholder="Username/Email"
                autocomplete="off"
                v-model.trim="$v.form.username.$model"
                @input="$v.form.username.$touch()"
                :state="!$v.form.username.$error"
              ></b-form-input>
              <b-form-invalid-feedback id="username-input">
                <span v-if="!$v.form.username.required">
                  Username is required
                </span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
              <b-form-input
                id="password"
                aria-describedby="password-input"
                type="password"
                placeholder="Password"
                autocomplete="off"
                v-model.trim="$v.form.password.$model"
                @input="$v.form.password.$touch()"
                :state="!$v.form.password.$error"
              ></b-form-input>
              <b-form-invalid-feedback id="password-input">
                <span v-if="!$v.form.password.required">
                  Password is required
                </span>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-button
              class="mt-3"
              size="sm"
              type="submit"
              variant="success"
              block
              :disabled="$v.form.$invalid">
              Login
            </b-button>
          </b-form>
        </div>
      </b-col>
    </b-row>

    <!-- Loading -->
    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { required } from 'vuelidate/lib/validators';

  export default {
    data() {
      return {
        loading : false,
        form    : {
          username : null,
          password : null,
        },
      }
    },
    computed : {
      ...mapGetters({
        authStatus : 'auth/authStatus',
      }),
    },
    components : {
      'AppLoaderAdmin' : () => import('@/components/layout/AppLoaderAdmin'),
    },
    watch : {
      authStatus(val) {
        if (val)
          this.loading = (val === 'loading') ? true : false;
      },
    },
    methods : {

      /**
       * Admin Login
       */
      adminLogin() {
        this.$store.dispatch('auth/login', {
          email    : this.form.username,
          password : this.form.password,
          isAdmin  : true,
        });
      },
    },
    validations : {
      form : {
        username : { required },
        password : { required },
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/scss/settings';

  #admin-login-page {
    min-height: 100vh;
    background-color: $primary-color;
  }

  .login-form {
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(119, 119, 119, 0.4);
  }

  .h3-logo {
    width: 13em;
    margin: 6rem 0 2em 0;
    text-align: center;
  }
</style>